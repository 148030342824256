const {
  dom,
  dom: {
    getParentByClassName,
    hasClassInParents,
    stringToDocumentFragment,
    getBodyElement,
    getFirstElementByTagName,
    getHeadElement
  }
} = window.o_util;

export { dom, getBodyElement, getFirstElementByTagName, getHeadElement, getParentByClassName, hasClassInParents, stringToDocumentFragment };
/*                            */

import { dom } from "../../common/src/global";
import { clickHandler } from "./textExpander";
import { TextExpanderEvents } from "./textExpanderEvents";

window.o_global.events<TextExpanderEvents>().on("assets.textExpander.init", (selector = "") => {
  /*                                          */
  const textExpanderToggles = document.querySelectorAll(
    ` ${selector} .js_pl_text-expander__link`
  ) as NodeListOf<HTMLElement>;

  textExpanderToggles.forEach((toggleElement) => {
    let hasMoreContentThanDisplayed = false;

    if (toggleElement.classList.contains("pl_text-expander__link")) {
      toggleElement.parentNode?.addEventListener("click", clickHandler);
    } else {
      toggleElement.addEventListener("click", clickHandler);
    }

    const textExpander = dom.getParentByClassName(toggleElement, "pl_text-expander") as HTMLElement;
    const isOpen = textExpander.classList.contains("pl_text-expander--expanded");

    if (!isOpen) {
      /*    */
      textExpander.classList.remove("pl_text-expander--has-more");

      const checkForMoreContentCSSClass = "pl_text-expander--check-has-more";

      /*                                                       */
      /*                                                                      */
      textExpander?.classList.add(checkForMoreContentCSSClass);

      /*                                                                                               */
      hasMoreContentThanDisplayed = textExpander.clientHeight < textExpander?.scrollHeight;

      /*                                      */
      textExpander?.classList.remove(checkForMoreContentCSSClass);

      if (hasMoreContentThanDisplayed) {
        textExpander.classList.add("pl_text-expander--has-more");
      } else {
        toggleElement.parentElement?.style.setProperty("display", "none");
        textExpander.style.height = "auto";
        textExpander.style.minHeight = "initial";
      }
    }

    window.o_global.events<TextExpanderEvents>().emit("assets.textExpander.initialised", {
      isOpen,
      hasMoreContentThanDisplayed,
      target: textExpander,
    });
  });
});

window.o_global.eventLoader.onReady(130, () => {
  window.o_global.events<TextExpanderEvents>().emit("assets.textExpander.init", "body");
});

export type { TextExpanderEvents };

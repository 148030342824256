const tracking = otto.tracking;
const {
  submitEvent,
  submitEventMerge,
  submitMerge,
  submitMove,
  submitMiniAction,
  createEventMergeContext,
  trackOnNextPageImpression,
  createContext,
  replaceContext,
  closeContext,
  getPageMergeId
} = tracking;
function get() {
  return tracking;
}
export {
  closeContext,
  createContext,
  createEventMergeContext,
  get,
  getPageMergeId,
  replaceContext,
  submitEvent,
  submitEventMerge,
  submitMerge,
  submitMiniAction,
  submitMove,
  trackOnNextPageImpression,
  tracking
};
/*                                */
